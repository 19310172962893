<template>
  <v-dialog :value="value" persistent max-width="480">
    <v-card>
      <v-card-title>
        {{ $t('global.leave_site.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('global.leave_site.text') }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn x-large color="primary" outlined @click="stay()">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn x-large color="primary" @click="leave()">
          {{ $t('global.leave_site.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'DialogLeavePage',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    leave() {
      this.$emit('leave')
    },
    stay() {
      this.$emit('stay')
    },
  },
})
</script>

<style lang="sass"></style>
